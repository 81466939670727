<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card>
          <v-card-title class="d-flex justify-center" primary-title>
            Informações básicas
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-center mb-10">
                <LogoForm @store="$auth.getLoginData()" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <app-text-field v-model="form.name" label="Nome" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <app-icon left>settings_suggest </app-icon> Outras Configurações
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <app-select
                  :items="items"
                  v-model="form.customer_alias"
                  label="Como gostaria de chamar seu cliente"
                />
              </v-col>
              <v-col md="6">
                <app-number-field
                  :precision="0"
                  v-model="form.session_duration_default"
                  label="Duração da sessão (em minutos)"
                />
              </v-col>
            </v-row>

            <h3 class="mt-10 mb-4">
              <app-icon left>contact_phone</app-icon>Contatos
            </h3>

            <v-row>
              <v-col cols="6">
                <app-whatsapp-field v-model="form.phone_wa" label="WhatsApp" />
              </v-col>
            </v-row>

            <h3 class="mt-10 mb-4">
              <app-icon left>pin_drop</app-icon>Endereço
            </h3>
            <v-row>
              <v-col cols="12" md="4">
                <app-cep-field
                  v-model="main_address.postal_code"
                  label="CEP"
                  @address="setAddress($event)"
                />
              </v-col>
              <v-col cols="12" md="12">
                <app-text-field
                  v-model="main_address.address"
                  label="Endereço"
                />
              </v-col>
              <v-col cols="12" md="6">
                <app-text-field v-model="main_address.number" label=" Número" />
              </v-col>
              <v-col cols="12" md="6">
                <app-text-field
                  v-model="main_address.district"
                  label="Bairro"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="6">
                <app-text-field v-model="main_address.city" label="Cidade" />
              </v-col>
              <v-col cols="4" md="6">
                <app-text-field v-model="main_address.state" label="Estado" />
              </v-col>
              <v-col cols="12">
                <app-text-field
                  v-model="main_address.complement"
                  label="Complemento"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="$router.go(-1)" text> Cancelar </v-btn>
            <v-btn @click="save()" class="mr-2" color="primary"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card>
          <v-card-text>
            <CompanyWorkingDaysForm />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
import LogoForm from "@/components/app/forms/LogoForm";
import CompanyWorkingDaysForm from "@/components/settings/forms/CompanyWorkingDaysForm.vue";

export default {
  components: {
    LogoForm,
    CompanyWorkingDaysForm,
  },
  data() {
    return {
      info: "Dica: você pode utilizar as tags @nome_paciente para substituir pelo nome do paciente / @data_sessao para substituir pela data da cobrança",
      items: [
        {
          text: "Cliente",
          value: "customer",
        },
        {
          text: "Paciente",
          value: "patient",
        },
      ],
      form: {
        customer_alias: null,
      },
      main_address: {
        address: null,
        complement: null,
        district: null,
        number: null,
        postal_code: null,
        city: null,
        state: null,
        type: "mainAddress",
      },
    };
  },
  computed: {
    company() {
      return this.$store.state.auth.company;
    },
  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.company));
    if (this.company.main_address) {
      this.main_address = JSON.parse(JSON.stringify(this.company.main_address));
    }
  },
  methods: {
    save() {
      this.form.main_address = this.main_address;
      this.$loading.start();
      this.$http
        .update("company/company", this.company.id, this.form)
        .then((response) => {
          // this.$router.push("/configuracoes");
          this.processSaved();
          this.$auth.getLoginData();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    processSaved() {
      this.$loading.finish();
      this.$snackbar({
        message: "Configurações salvas com sucesso!",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
    setAddress(address) {
      this.main_address = address;
    },
  },
};
</script>

<style></style>
