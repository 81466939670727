<template>
  <v-badge color="transparent" avatar overlap>
    <template v-slot:badge>
      <v-btn
        v-if="company.media.length"
        @click="deleteCompanyAvatar()"
        text
        icon
        color="error"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-avatar size="150px">
      <v-img
        v-if="company.media.length"
        @click="handleAvatarClick()"
        :src="image"
      />
      <div v-else>
        <v-icon size="100px" @click="handleAvatarClick()"
          >mdi-image-plus</v-icon
        >
        <br />
        Adicionar logo
      </div>
      <input
        class="d-none"
        ref="fileInput"
        type="file"
        accept="image/*"
        @input="handleFileInput()"
      />
    </v-avatar>
  </v-badge>
</template>

<script>
import MediaMixin from "@/mixins/Media/MediaMixin";

export default {
  mixins: [MediaMixin],
  data: () => ({
    image: null,

    form: {
      logo: null,
    },
  }),

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    companyAvatarPath() {
      return this.getImage(this.company.media[0]);
    },
  },

  watch: {
    companyAvatarPath(val) {
      console.log(val);
      this.image = val;
    },
  },

  created() {
    this.image = this.companyAvatarPath;
  },

  methods: {
    deleteCompanyAvatar() {
      this.$loading.start();
      this.$http
        .destroy("company/logo", this.company.media[0].id)
        .then((response) => {
          this.$emit("store");
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },
    handleAvatarClick() {
      this.$refs.fileInput.click();
    },

    handleFileInput() {
      let input = this.$refs.fileInput;
      let files = input.files;

      if (files && files[0]) {
        let reader = new FileReader();

        reader.onload = (e) => {
          this.image = e.target.result;
          this.form.logo = files[0];
          this.storeMedia();
        };

        reader.readAsDataURL(files[0]);
      }
    },

    getFormData() {
      let form = new FormData();

      form.append("logo", this.form.logo);

      return form;
    },

    async storeMedia() {
      this.$loading.start();
      await this.$http
        .store("company/logo", this.getFormData())
        .then((response) => {
          this.$emit("store");
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>