<template>
  <div>
    <v-row>
      <v-col>
        <h2>Horário de funcionamento da clínica</h2>
        <span>
          Configure os dias e faixa de horário que irão aparecer na sua agenda.
        </span>
      </v-col>
    </v-row>
    <template v-for="(working_day, index) in workingHours">
      <v-row :key="index">
        <v-col md="3" cols="7" class="pt-0">
          <v-switch
            v-model="working_day.is_working"
            :label="weekDayPtBr[working_day.week_day]"
            :true-value="true"
            :false-value="false"
            :hide-details="true"
          />
        </v-col>
        <template v-if="working_day.is_working">
          <v-col
            md="2"
            cols="6"
            class="d-flex align-baseline justify-space-between"
          >
            <span class="pr-5">De</span>
            <app-time-picker
              :hideLabel="true"
              v-model="working_day.starts_at"
            />
          </v-col>
          <v-col
            md="2"
            cols="6"
            class="d-flex align-baseline justify-space-between"
          >
            <span class="pr-5">às</span>
            <app-time-picker :hideLabel="true" v-model="working_day.ends_at" />
          </v-col>
        </template>
        <template v-else>
          <v-col class="d-flex align-end justify-end justify-md-start">
            <span class="mr-3">Fechado</span>
          </v-col>
        </template>
      </v-row>
    </template>
    <v-row>
      <v-col class="text-right">
        <v-btn @click="save()" class="mr-md-2" color="primary">Salvar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    workingHours: [
      {
        week_day: "monday",
        is_working: true,
        starts_at: "08:00:00",
        ends_at: "18:00:00",
        week_day_number: 2,
      },
      {
        week_day: "tuesday",
        is_working: true,
        starts_at: "08:00:00",
        ends_at: "18:00:00",
        week_day_number: 3,
      },
      {
        week_day: "wednesday",
        is_working: true,
        starts_at: "08:00:00",
        ends_at: "18:00:00",
        week_day_number: 4,
      },
      {
        week_day: "thursday",
        is_working: true,
        starts_at: "08:00:00",
        ends_at: "18:00:00",
        week_day_number: 5,
      },
      {
        week_day: "friday",
        is_working: true,
        starts_at: "08:00:00",
        ends_at: "18:00:00",
        week_day_number: 6,
      },
      {
        week_day: "saturday",
        is_working: false,
        starts_at: null,
        ends_at: null,
        week_day_number: 7,
      },
      {
        week_day: "sunday",
        is_working: false,
        starts_at: null,
        ends_at: null,
        week_day_number: 1,
      },
    ],

    weekDayPtBr: {
      monday: "Segunda-feira",
      tuesday: "Terça-feira",
      wednesday: "Quarta-feira",
      thursday: "Quinta-feira",
      friday: "Sexta-feira",
      saturday: "Sábado",
      sunday: "Domingo",
    },
  }),

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.$http.index("/company/working-hours").then((response) => {
        // console.log(response.workingHours)
        if (response.workingHours.length > 0) {
          this.workingHours = response.workingHours;
        }
      });
    },

    save() {
      this.$loading.start();
      this.$http
        .store("/company/working-hours", {
          workingHours: this.workingHours,
        })
        .then((response) => {
          this.$snackbar({
            message: "Horário de funcionamento atualizado",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
